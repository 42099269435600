
const defaultSettings = {

  title: '文创COC后台',

  errorLog: 'prod',
  /**
   * 页面标签的title
   */
  /**
   * 菜单的title
   */
  sidebarTitle: '后台管理',
  /**
   * 侧边栏主题色，深色主题theme-dark，浅色主题theme-light，默认深色theme-dark
   */
  sideTheme: 'theme-dark',
  /**
   *是否固定头部
   */
  fixedHeader: true,
  /**
   * 是否展示右侧布局配置panel页面，在头部右侧个人信息布局设置控制
   */
  showSettings: false,
  /**
   * 是否显示顶部导航
   */
  topNav: false,
  /**
   * 是否显示 tagsView
   */
  tagsView: true,
  /**
   * 是否显示左侧菜单logo
   */
  sidebarLogo: true,
  /**
   * 是否显示动态标题
   */
  dynamicTitle: true,
  /**
   * 背景图片高斯模糊像素
   */
  loginBgBlur: '20px',
}

export default  defaultSettings ;


